import React from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import { useStaticQuery, graphql } from "gatsby"

// Pulling default image from Contentful
const getImage = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ProjectHero = ({ img, className, children, homeFlag }) => {
  const data = useStaticQuery(getImage)
  return (
    <div>
      <BackgroundImage
        className={className}
        fluid={img || data.defaultBcg.childImageSharp.fluid}
        homeFlag={homeFlag}
      >
        {children}
      </BackgroundImage>
    </div>
  )
}

export default styled(ProjectHero)`
  min-height: calc(100vh - 62px);
  background: "none";
  background-position: center;
  background-size: cover;
  /* If we don't add the following opcacity, it uses a default .99 opacity that fucks everything up */
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
`
