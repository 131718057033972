import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ProjectHero from "../components/ProjectHero"
import styles from "../css/template.module.css"
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../components/SEO"
import Fade from "react-reveal/Fade"
import Slide from "react-reveal/Slide"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

function Template({ data }) {
  const {
    name,
    location,
    services,
    category,
    richDescription: { json },
    images,
    coverImg,
  } = data.project

  // Grab the first image and store it in nainImage and the rest will be sotored
  // in projectImages
  const projectImages = images
  return (
    <Layout>
      <SEO title={name} />
      <ProjectHero img={coverImg.fluid} homeFlag>
        <div className={styles.upperCover}>
          <Slide left duration={1500}>
            <h1 className={styles.projectTitle}>{name}</h1>
          </Slide>
        </div>
        <div className={styles.lowerCover}>
          <Slide right delay={1000}>
            <section className={styles.info}>
              <div className={styles.infoChunk}>
                <div className={styles.infoHeadline}>Servicios</div>
                <div className={styles.infoDescription}>
                  {category || "Construcción"}
                </div>
              </div>
              <div className={styles.infoChunk}>
                <div className={styles.infoHeadline}>Ubicación</div>
                <div className={styles.infoDescription}>
                  {location || "Edomex, MX"}
                </div>
              </div>
              <div className={styles.infoChunk}>
                <div className={styles.infoHeadline}>Categoría</div>
                <div className={styles.infoDescription}>
                  {services || "Residencial"}
                </div>
              </div>
            </section>
          </Slide>
        </div>
      </ProjectHero>
      <section className={styles.template}>
        <section className={styles.concept}>
          <Fade bottom>
            <article className={styles.innerConcept}>
              {documentToReactComponents(json)}
            </article>
          </Fade>
          <div className={styles.conceptTitle}>
            <h2>Concepto</h2>
          </div>
        </section>
        <div className={styles.images}>
          {projectImages.map((item, index) => {
            console.log(item.fluid.aspectRatio)
            return (
              <Fade bottom fraction=".05">
                <Img
                  key={index}
                  fluid={item.fluid}
                  alt="Proyecto"
                  className={
                    (item.fluid.aspectRatio < 1
                      ? styles.portrait
                      : styles.landscape) +
                    " " +
                    styles.images
                  }
                />
              </Fade>
            )
          })}
        </div>
        <div className={styles.center}>
          <AniLink fade to="/projects" className="btn-primary">
            Más Proyectos
          </AniLink>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    project: contentfulProjects(slug: { eq: $slug }) {
      name
      location
      services
      richDescription {
        json
      }
      images {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyContentfulFluid
        }
      }
      coverImg {
        fluid(quality: 99, maxWidth: 4160) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`
// ...GatsbyContentfulFluid

export default Template
